<template>
  <v-row>
    <v-col cols="12" sm="12" md="11" lg="11">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-autocomplete
            outlined
            flat
            dense
            append-icon="mdi-magnify"
            :items="paymentGeneralKasbons"
            item-text="voucherNumber"
            return-object
            v-model="kasbon"
            label="KASBON"
            @change="onChangeKasbon($event, 'k')"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-autocomplete
            outlined
            flat
            dense
            append-icon="mdi-magnify"
            :items="paymentSettlementKasbons"
            item-text="voucherNumber"
            return-object
            v-model="settlement"
            label="SETTLEMENT"
            @change="onChangeKasbon($event, 's')"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <v-data-table
            :headers="headerKasbon"
            :items="kasbons"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:item.no="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:item.action="{ index }">
              <v-btn icon color="error" @click="deleteKasbon(index)"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-data-table
            :headers="headers"
            :items="items"
            disable-pagination
            hide-default-footer
            @click:row="onClickRow"
          >
            <template v-slot:item.no="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:item.amount="{ item }">
              {{ formatPrice(item.amount) }}
            </template>
            <template v-slot:item.rate="{ item }">
              {{ formatPrice(item.rate) }}
            </template>
            <template v-slot:item.amountIDR="{ item }">
              {{ formatPrice(item.amountIDR) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="12" md="1" lg="1">
      <v-row class="py-0 mb-2">
        <v-col cols="12" class="py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="success" v-on="on" @click="update">
                <v-icon>
                  mdi-content-save
                </v-icon>
              </v-btn>
            </template>
            <span>Save</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="py-0 mb-2">
        <v-col cols="12" class="py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" v-on="on" @click="back">
                <v-icon>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
            </template>
            <span>Back</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
    <dialog-journal
      :item="item"
      :index="index"
      :dialog="dialog"
      :accounts="accounts"
      @close="close"
      @save="addItem"
      @deleteItem="deleteItem"
    ></dialog-journal>
  </v-row>
</template>

<script>
import DialogJournal from "@/components/DialogJournal.vue";
import { mapState } from "vuex";
export default {
  name: "add-addtional-payment",
  components: {
    DialogJournal,
  },
  props: {
    id: Number,
  },
  computed: {
    totalDebit() {
      let total = 0;
      this.items.forEach(x => {
        if (x.type === "Debit") {
          total += x.amount;
        }
      });
      return total.toFixed(2);
    },
    totalCredit() {
      let total = 0;
      this.items.forEach(x => {
        if (x.type === "Credit") {
          total += x.amount;
        }
      });
      return total.toFixed(2);
    },
    ...mapState("paymentGeneralKasbon", ["paymentGeneralKasbons"]),
    ...mapState("paymentSettlementKasbon", ["paymentSettlementKasbons"]),
  },
  data: () => ({
    valid: true,
    dialog: false,
    item: {},
    index: -1,
    account: null,
    kasbon: null,
    settlement: null,
    items: [],
    tempItems: [],
    kasbons: [],
    accounts: [],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account #",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "D/C",
        value: "type",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Reference",
        value: "reference",
        sortable: false,
        divider: true,
      },
      {
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
      },
    ],
    headerKasbon: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Voucher #",
        value: "voucherNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Type",
        value: "type",
        sortable: false,
        divider: true,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
      },
    ],
  }),
  methods: {
    update() {
      if (this.items.length > 0 && this.kasbons.length > 0) {
        if (this.totalDebit - this.totalCredit === 0) {
          this.$store.commit("SET_LOADER", true);
          this.$store
            .dispatch("combinationKasbon/mergeJournal", { id: this.id, body: this.kasbons })
            .then(() => {
              this.$store
                .dispatch("combinationKasbon/mergeJournal", { id: this.id, body: this.items })
                .then(() => this.$store.commit("SET_LOADER", false))
                .catch(() => {
                  this.$store.dispatch("toast", {
                    type: "error",
                    message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
                  });
                  this.$store.commit("SET_LOADER", false);
                });
            })
            .catch(() => {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
              this.$store.commit("SET_LOADER", false);
            });
        } else {
          this.$store.dispatch("toast", { type: "error", message: "Journal not balance" });
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Accounts or Kasbon or Settlement is required",
        });
      }
    },
    deleteItem(data) {
      if (data.index > -1) {
        this.items.splice(data.index, 1);
        this.index = -1;
        this.dialog = false;
      }
    },
    addItem(item) {
      if (this.index > -1) {
        this.items[this.index] = item;
        this.index = -1;
      } else {
        this.items.push(item);
      }
    },
    deleteKasbon(index) {
      this.kasbons.splice(index, 1);
      this.tempItems.forEach(x => {
        this.items.splice(
          this.items.findIndex(y => y === x),
          1
        );
      });
    },
    close() {
      this.index = -1;
      this.dialog = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.items.findIndex(x => x === item);
      this.dialog = true;
    },
    addAccount(item) {
      item.reference = this.items[0].reference;
      item.remark = this.items[0].remark;
      this.item = item;
      this.dialog = true;
    },
    onChangeKasbon(val, type) {
      this.kasbons.push({ id: val.id, type: type, voucherNumber: val.voucherNumber });
      this.getJournal(val.id, type);
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    back() {
      this.$router.go(-1);
    },
    async getMergeJournal() {
      await this.$store.dispatch("combinationKasbon/getMergeJournal", this.id).then(response => {
        this.items = response.data;
      });
    },
    async getJournal(id, type) {
      await this.$store
        .dispatch("combinationKasbon/getJournal", { id: id, type: type })
        .then(response => {
          this.tempItems = response.data;
          this.tempItems.forEach(x => this.items.push({ ...x }));
        });
    },
    async getAcocunts() {
      await this.$store
        .dispatch("chartOfAccount/getAccounts")
        .then(response => (this.accounts = response.data));
    },
    getInnitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getAcocunts(),
        this.getMergeJournal(),
        this.$store.dispatch("paymentGeneralKasbon/getAll"),
        this.$store.dispatch("paymentSettlementKasbon/getAll"),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInnitialData();
  },
};
</script>

<style></style>
